@charset 'UTF-8';

// Fonts
// @import url('https://fonts.googleapis.com/css?family=Muli:300,400,700|Open+Sans:300,400,600,700|Roboto+Mono');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&family=Roboto+Mono&display=swap');

// Bootstrap & Variables
@import "../../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "variables-custom";
@import "../../../node_modules/bootstrap/scss/bootstrap";


// Components
@import "layout/_layout";
@import "components/_components";
