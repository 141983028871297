// Custom Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Typography
$font-size-list:           ( 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 50, 60, 70, 80, 90, 100 );
$letter-spacing:           0.05em;
$letter-spacing-sm:        ($letter-spacing / 2);
$letter-spacing-lg:        ($letter-spacing * 2);

@font-face { font-family: Redacted; src: url("../fonts/redacted-regular.woff2") format("woff2"), url("../fonts/redacted-regular.woff") format("woff"), url("../fonts/redacted-regular.otf.html") format("opentype"), url("../fonts/redacted-regular.svg#filename") format("svg"); }

.block { font-family: Redacted; }
.unblock { font-family: Mulish; padding: 0px 6px; font-weight: 700;}

.display-5-fluid {
  font-size: 22px; /* Some tweener fallback that doesn't look awful */
  font-size: calc(1vw + 1vh + 1.1vmin);
}

// Layout
$section-padding:          6.25rem;


.grid {
  background: #EEE;
  /* center */
  margin: 0 auto;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- grid-item ---- */
/* fluid 5 columns */
.grid-sizer,
.grid-item { width: 33%; }
/* 2 columns */
.grid-item--width2 { width: 66%; }


.case-study .owl-item img {
//	  width: 100%;
//  max-width: 90vw;
//  height: calc(100vw * (9/16));
  max-height: 80vmin;
}
.color .color-example-small {
    width: $h4-font-size;
    height: $h4-font-size;
    margin-right: 20px;
    border-radius: 50%;
}
.figure .figure-caption.simple {
	background: none;
	border: none;
	max-width: 100%;
  padding: 0 2%;
    -webkit-transform: translate(0, 85%);
    -moz-transform: translate(0, 85%);
    transform: translate(0, 85%);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.antetitle {
  font-size: $font-size-sm;
    color: $gray-300;
		margin-bottom:0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    &.copied {
      opacity: 1;
      color: $gray-600;
    }

  &:hover {
      color: $gray-600;
  }
}



.dot {
    position: relative;
    width: $h4-font-size;
    height: $h4-font-size;
    border-radius: 50%;
    margin-right: 20px;
}
.dot:before {
  animation: dotpulse 5s infinite ease-in-out;
    content: "";
    display: block;
    left: -5px;
    top: -5px;
    width: $h4-font-size;
    height: $h4-font-size;
    border-radius: 50%;
}

.dot.thoughtful, .dot.thoughtful:before {
  background: #FBD2D7;
    animation-delay: 0s;
}
.dot.purposeful, .dot.purposeful:before {
  background: #FF3C38;
    animation-delay: 0.3s;
}
.dot.respectful, .dot.respectful:before {
  background: #51596d;
    animation-delay: 0.6s;
}
.dot.empowering, .dot.empowering:before {
  background: #fcd116;
    animation-delay: 0.9s;
}
.dot.inclusive, .dot.inclusive:before {
  background: #40E0D0;
    animation-delay: 1.2s;
}
.dot.transparent, .dot.transparent:before {
  background: #add8e6;
    animation-delay: 1.5s;
}
@keyframes dotpulse {
    0%{
        transform: scale(0.5);
        opacity: 1;
    }
    100%{
        transform: scale(2);
        opacity: 0;
    }
}


// Colors
.bg-gray-700 { background-color: $gray-700;}
.bg-gray-100 { background-color: $gray-100;}
.bg-gray-200 { background-color: $gray-200;}
.bg-gray-300 { background-color: $gray-300;}
.bg-gray-400 { background-color: $gray-400;}
.bg-gray-500 { background-color: $gray-500;}
.bg-gray-600 { background-color: $gray-600;}
.bg-gray-700 { background-color: $gray-700;} // 555555
.bg-gray-800 { background-color: $gray-800;}
.bg-gray-900 { background-color: $gray-900;}
.bg-black   { background-color: $black;}

$dark:          $gray-700 !default;

$facebook:                 #3b5998;
$google-plus:              #dd4b39;
$twitter:                  #00aced;
$linkedin:                 #007bb6;
$pinterest:                #cb2027;
$git:                      #666666;
$tumblr:                   #32506d;
$vimeo:                    #aad450;
$youtube:                  #bb0000;
$flickr:                   #ff0084;
$reddit:                   #ff4500;
$dribbble:                 #ea4c89;
$skype:                    #00aff0;
$instagram:                #517fa4;
$behance:                  #1769ff;
$rss:                      #f26522;

$socials: ();
$socials: map-merge((
  "facebook": $facebook,
  "google-plus": $google-plus,
  "twitter": $twitter,
  "linkedin": $linkedin,
  "pinterest": $pinterest,
  "git": $git,
  "tumblr": $tumblr,
  "vimeo": $vimeo,
  "flickr": $flickr,
  "reddit": $reddit,
  "dribbble": $dribbble,
  "skype": $skype,
  "instagram": $instagram,
  "behance": $behance,
  "rss": $rss
), $socials);

// Header
.header-transparent { background: rgba($gray-700, .25); }

.headroom--not-top.headroom--pinned {
	.header-transparent { background: $gray-700; }
	.header-minimal-dark { background: $gray-700; }
}

@media (min-width: 992px) {
.navbar-nav .nav-link {
    border-top-width: 2px !important;
      text-transform: none !important;
}
.nav-link.active {
    font-weight: 700;
  }
}
